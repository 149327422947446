@if (label.length) {
  <label>{{label}}</label>
}
@if (admin) {
<!--  <p-dropdown-->
<!--    [options]="optionsList"-->
<!--    [filter]="true"-->
<!--    [(ngModel)]="selectedValue"-->
<!--    (onChange)="onChange($event)"-->
<!--    [showClear]="false"-->
<!--    [placeholder]="placeholder"/>-->
  <p-dropdown
    [options]="optionsList"
    [(ngModel)]="selectedValue"
    (onChange)="onChange($event)"
    [filter]="true"
    [showClear]="false"
    [placeholder]="placeholder">
    <ng-template let-val pTemplate="item" style="display: none">
      <div class="flex align-items-center gap-2">
<!--        <img src="https://portal.management-tools.comradeserver.com/assets/glf-logo.svg"/>-->
        <div>{{val.label.split('*')[0]}} <b>({{val.abbr_l}})</b></div>
        <div class="drop-down-admin-platform__date">{{val.automatic_lead_qualification_start | date: 'MMM, y'}}</div>
      </div>
    </ng-template>
  </p-dropdown>
} @else {
  <p-dropdown
    [options]="optionsList"
    [filter]="false"
    [(ngModel)]="selectedValue"
    (onChange)="onChange($event)"
    [showClear]="false"
    [placeholder]="placeholder"/>
}

<!--<select class="custom-select" (change)="onChange($event)">-->
<!--  @for (option of optionsList; track option.value) {-->
<!--    <option [value]="option.value">{{option.label}}</option>-->
<!--  }-->
<!--</select>-->
