import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DatePipe, JsonPipe } from '@angular/common';


@Component({
  selector: 'app-select',
  standalone: true,
  imports: [DropdownModule, FormsModule, JsonPipe, DatePipe],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
})
export class SelectComponent implements OnInit {
  ready: boolean = false;
  // public selectedValue: string | undefined;
  @Input({ required: true }) optionsList: SelectItem[];
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() admin: boolean = false;
  @Input() activeItem: any = null;
  @Input() selectedValue: any = null;
  @Output() selectedOption = new EventEmitter<any>();

  public months: any[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  constructor() {
    this.optionsList = [];
  }

  ngOnInit() {
    if (this.admin) {
      this.optionsList.forEach((el: any) => {
        el.label = el.label + '*' + el.abbr_l
      });

      setTimeout(() => {
        if (this.activeItem) { // ID
          this.selectedValue = this.activeItem.value || this.activeItem;
        } else {
          this.selectedValue = this.optionsList[0].value;
        }

        const selectedItem = this.optionsList.find((x: any) => (x.value === this.activeItem.value)) || this.optionsList[0].value;
        if (document.querySelector('header app-select .p-dropdown-label')) {
          // @ts-ignore
          document.querySelector('header app-select .p-dropdown-label').innerHTML  = selectedItem?.label?.split('*')[0];
        }
        this.selectedOption.emit(this.selectedValue);
      }, 100);
    } else {
      setTimeout(() => {
        if (this.label === 'Date range') {
          const lastFullMonth = this.getLastFullMonth();
          const lastFullMonthStringFormat = this.months[lastFullMonth.month] + ' ' + lastFullMonth.year;
          this.selectedValue = this.optionsList.find((o: any) => o.label === lastFullMonthStringFormat)?.value;
        } else if (this.activeItem) { // ID
          this.selectedValue = this.activeItem.value || this.activeItem;
        } else {
          this.selectedValue = this.optionsList[0].value;
        }

        if (this.selectedValue === undefined) {
          // dev-mode
          this.selectedValue = this.optionsList[0].value;
        }
        this.selectedOption.emit(this.selectedValue);
      }, 120);
    }
  }
  onChange(event: any) {
    console.log('select event', event);
    if (this.admin) {
      setTimeout(() => {
        const selectedItem = this.optionsList.find((x: any) => x.value === this.selectedValue);
        // @ts-ignore
        document.querySelector('header app-select .p-dropdown-label').innerHTML  = selectedItem?.label?.split('*')[0];
        this.selectedOption.emit(event.value);
      }, 100);
    } else {
      this.selectedOption.emit(event.value);
    }
  }

  private getLastFullMonth() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    if (month === 0) {
      return {
        year: year - 1,
        month: 12
      };
    } else {
      return {
        year: year,
        month: month - 1
      };
    }
  }
}
